<script>
  import { createEventDispatcher, onMount } from "svelte"

  import Button from "atoms/Button.svelte"
  import Checkbox from "atoms/Checkbox.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import ImageInput from "atoms/ImageInput.svelte"
  import { mapErrors } from "utils/errors"
  import {
    getImageUrl,
    isBase64,
    blobFromBase64,
    contentTypeFromBase64,
  } from "utils/image"
  import * as API from "apis/backend"

  const dispatch = createEventDispatcher()

  let className = ""
  export { className as class }

  export let topic
  let id
  let name = ""
  let disabledMessage = ""
  let paid = false
  let productOptions = ""
  let image
  let enabled = false
  let certification = false
  let errors = {}

  onMount(function () {
    if (topic) {
      id = topic.id
      name = topic.name || ""
      disabledMessage = topic.disabledMessage || ""
      productOptions = topic.productOptions || ""
      paid = topic.paid || false
      image = topic.image && getImageUrl(`/topics/${topic.id}/image`)
      enabled = topic.enabled || false
      certification = topic.certification || false
    }
  })

  async function submit() {
    const apiFn = id ? API.putTopic : API.postTopic
    const successStatus = id ? 200 : 201
    const imageContentType = contentTypeFromBase64(image)
    const payload = {
      ...(id ? { id } : {}),
      name,
      disabledMessage,
      paid,
      productOptions,
      enabled,
      imageContentType,
      certification,
    }
    const [data, meta] = await apiFn(payload)
    if (meta.status === successStatus) {
      if (isBase64(image) && data.imageUploadUrl)
        await fetch(data.imageUploadUrl, {
          method: "PUT",
          body: blobFromBase64(image),
          headers: { "Content-Type": imageContentType },
        })
      topic = data
      return dispatch("success", topic)
    }

    errors = mapErrors(data)
  }
</script>

<form class={className} on:submit|preventDefault={submit}>
  <TextInput label="Nome" class="mb-6" errors={errors.name} bind:value={name} />
  <TextInput
    label="Mensagem (Desabilitado)"
    class="mb-6"
    errors={errors.disabledMessage}
    bind:value={disabledMessage} />
  <TextInput
    label="Hash de Compra"
    class="mb-6"
    errors={errors.productOptions}
    bind:value={productOptions} />
  <ImageInput class="mb-6" bind:value={image} label="Imagem" />
  <Checkbox bind:checked={enabled} class="mb-6">Habilitado</Checkbox>
  <Checkbox bind:checked={paid} class="mb-6">Curso Pago</Checkbox>
  <Checkbox bind:checked={certification} class="mb-6">
    Emite Certificado
  </Checkbox>
  <Button>Salvar</Button>
</form>
